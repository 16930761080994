@font-face {
  font-family: 'HumstSlab712 BT';
  src: url('./assets/fonts/HumstSlab712\ BT\ Roman.ttf') format('truetype');
}

@font-face {
  font-family: 'HumstSlab712 Blk BT';
  src: url('./assets/fonts/HumstSlab712\ BT\ Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura Bold';
  src: url('./assets/fonts/FuturaStdBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura Book';
  src: url('./assets/fonts/FuturaStdBook.ttf') format('truetype');
}

@font-face {
  font-family: 'Egyptienne';
  src: url('./assets/fonts/URWEgyptienneTOT-Lig.otf') format('opentype');
}

.futura-bold {
  font-family: 'Futura Bold';
}

.futura-book {
  font-family: 'Futura Book';
}

.egyptienne {
  font-family: 'Egyptienne';
}

.humst-roman {
  font-family: 'HumstSlab712 BT';
}

.humst-black {
  font-family: 'HumstSlab712 Blk BT';
}

strong {
  font-family: 'Futura Bold';
}

* {
  font-family: 'HumstSlab712 BT';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

sup {
  font-family: inherit;
  font-size: 50%;
  top: -0.75em;
}

hr {
  margin: 0
}

h1 {
  font-size: 70px !important;
  width: fit-content;
  margin: 0;
}

h2 {
  font-size: 43px !important;
  width: fit-content;
  margin: 0;
}

h3 {
  font-size: 24px !important;
  width: fit-content;
  margin: 0;
}

p {
  font-size: 16px !important;
  width: fit-content;
  margin: 0;
}

img {
  max-width: 100%;
}

.fs-29 {
  font-size: 29px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 18px !important;
}

.fs-12 {
  font-size: 12px !important;
}


.gold {
  color: #FFB500;
}

.white {
  color: white;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-ac {
  display: flex;
  align-items: center;
}

.reg-icon {
  border-radius: 50%;
  min-width: 25px;
  height: 25px;
  font-size: 20px;
  border: 2px solid black;
  font-family: 'Futura Bold';
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
  transform: scale(.75);
}

.m-0 {
  margin: 0;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-75 {
  margin-top: 75px;
}

.mb-15 {
  margin-bottom: 15px;
}


.mb-24 {
  margin-bottom: 24px;
}

.ml-15 {
  margin-left: 15px;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid #ffb500;
}

.modal-dialog {
  margin-top: 35vh;

}