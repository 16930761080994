/* responsive media queries */
@media only screen and (max-width: 1100px) {
	.Navbar {}

	.pages {}

	.contentContainer {
		max-width: 800px;
	}

	.navContent {}

	.navbarLogo {}

	.navbarLinks {}

	.navbarLink {}

	.navbarLink.selected {}

	.navbarDropdownLink {}

	.navButton {}

	.navbarLinks .dropdown {}

	.navbarLinks .dropdown-menu {}

	.dropdown-item.active,
	.dropdown-item:active {}

	.navdropdown {}

	.navbarDropdownLink .dropdown-item {}

	.primaryButton {}

	.sub-nav-tagline {}

	.mainSection {}

	.productGrid {}

	.gridProduct {
		max-width: 373px;
	}

	.gridProduct img {}

	.contentAccordion {
		max-height: 150px;
	}

	.contentAccordion.expanded {}

	.accordionHeader {}

	.accordionHeader .titleImage {}

	.accordionHeader .titleImage img {}

	.accordionHeader .title {}

	.accordionHeader .expandIcon {}

	.iconContainer {}

	.goldBar {}

	.goldBar.h-bar {}

	.accordionBody {}

	.bulletPoint {}

	.darkContentBlock {}

	.contentSeparator {}

	.charTableLeft {}

	.charTableRight {}

	.floatingBox {}

	.floatingBox2 {}

	.floatingBox3 {}

	.gradientSection {}

	.gradientSection2 {}

	.gradientSection3 {}

	.instrumentPicture {}

	.numberCircle {}
}

@media only screen and (max-width: 800px) {
	.Navbar {}

	.pages {}

	.mainTitle {
		font-size: 55px;
	}

	.navContent {
		display: none;
	}

	.mobileNavContent {
		display: flex;
	}

	.contentContainer {
		max-width: 600px;
	}

	.mainSection img {
		display: none;
	}

	.tritanium-image1 {
		display: block !important;
	}

	.navContent {}

	.navbarLogo {}

	.navbarLinks {}

	.navbarLink {}

	.navbarLink.selected {}

	.navbarDropdownLink {}

	.navButton {}

	.navbarLinks .dropdown {}

	.navbarLinks .dropdown-menu {}

	.dropdown-item.active,
	.dropdown-item:active {}

	.navdropdown {}

	.navbarDropdownLink .dropdown-item {}

	.primaryButton {}

	.sub-nav-tagline {}

	.mainSection {}

	.productGrid {}

	.gridProduct {
		max-width: unset;
	}

	.gridProduct img {}

	.contentAccordion {
		max-height: 160px;
	}

	.contentAccordion.expanded {
		max-height: 4500px;
	}

	.accordionHeader {}

	.accordionHeader .titleImage {}

	.accordionHeader .titleImage img {}

	.accordionHeader .title {}

	.accordionHeader .expandIcon {}

	.iconContainer {}

	.goldBar {}

	.goldBar.h-bar {}

	.accordionBody {}

	.bulletPoint {}

	.darkContentBlock {}

	.contentSeparator {
		display: none;
	}

	.flex div {
		margin-top: 10px;
	}

	.charTableLeft {}

	.charTableRight {}

	.floatingBox {}

	.floatingBox2 {
		flex-wrap: wrap;
		padding: 50px 62px;
	}

	.floatingBox3 {}

	.gradientSection {}

	.gradientImages {
		width: 100%;
		height: 145px;
	}

	.gradientSection2 {}

	.gradientSection3 {}

	.instrumentPicture {}

	.numberCircle {}

	.instruments {
		justify-content: center;
	}

	.threeCircles {
		justify-content: center;
	}

	.threeCircles img {
		margin-top: 20px;
	}

	.reverseMobile {

		flex-direction: column-reverse;
	}

	.reverseMobile img {
		margin: 0 !important;
		padding-top: 50px;

	}

}

@media only screen and (max-width: 600px) {
	.Navbar {}

	.pages {}

	.contentContainer {
		max-width: 400px;
	}

	.navContent {}

	.navbarLogo {}

	.navbarLinks {}

	.navbarLink {}

	.navbarLink.selected {}

	.navbarDropdownLink {}

	.navButton {}

	.navbarLinks .dropdown {}

	.navbarLinks .dropdown-menu {}

	.dropdown-item:active {}

	.navdropdown {}

	.navbarDropdownLink .dropdown-item {}

	.primaryButton {}

	.sub-nav-tagline {}

	.mainSection {}

	.mainSection .tritanium-image2 {
		display: none;
	}

	.productGrid {}

	.gridProduct {
		max-width: unset;
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;
	}

	.gridProduct img {}

	.contentAccordion {
		max-height: 160px;
	}

	.contentAccordion.expanded {}

	.accordionHeader {}

	.accordionHeader .titleImage {
		display: none;
	}

	.accordionHeader .titleImage img {}

	.accordionHeader .title {}

	.accordionHeader .expandIcon {}

	.iconContainer {}

	.goldBar {}

	.goldBar.h-bar {}

	.accordionBody {}

	.bulletPoint {}

	.darkContentBlock {}

	.contentSeparator {}

	.charTableLeft {
		width: 100%;
	}

	.charTableRight {
		width: 100%;
	}

	.floatingBox {}

	.floatingBox2 {}

	.floatingBox3 {}

	.gradientSection {}

	.gradientSection2 {}

	.gradientSection3 {}

	.instrumentPicture {}

	.numberCircle {}


}