.Navbar {
	position: fixed;
	width: 100%;
	height: 60px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 20px #00000033;
	opacity: 1;
	display: flex;
	align-items: center;
	z-index: 10;
}

.mobileNavContent {
	display: none;
	justify-content: space-between;
}

.mobileNavContent .dropdown {
	position: unset;
}

.mobileNavbarLinks {
	position: absolute;
	background-color: white;
	top: 60px !important;
	left: 0;
	width: 100% !important;
	box-shadow: 0px 10px 10px #00000033;
	border: none;
	transform: unset !important;
	display: block !important;
}

.mobileNavContent .dropdown .navdropdown {
	max-height: 30px;
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;
}

.mobileNavContent .dropdown.show .navdropdown {
	max-height: 8px;
	transition: max-height 0.3s ease-in-out;
}

.mobileNavContent .dropdown .mobileNavbarLinks .contentContainer {
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;
}

.mobileNavContent .dropdown.show .mobileNavbarLinks .contentContainer {
	max-height: 500px;
	transition: max-height 0.3s ease-in-out;
}

.mobileNavbarLinks .navbarLink {
	margin: 0;
	padding: 0;
}

.mobileNavbarLinks .navbarLink .dropdown-item {
	padding-top: 10px;
	padding-bottom: 10px;
}

.mobileNavbarLinks .dropdown-item {
	padding: 0;
}

.mobileNavbarLinks .navButton {
	text-align: center;
}

.mobileNavbarLinks .navButton .dropdown-item {
	background-color: transparent !important;
}

.pages {
	padding-top: 60px;
}

.mainTitle {
	font-size: 70px;
	line-height: 1.2;
}

.contentContainer {
	max-width: 1100px;
	margin: 0 auto;
	padding: 0 20px;
	width: 100%;
}

.pictureBanner {
	height: 200px;
	object-fit: cover;
	width: 100%;
}

.navContent {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
}

.navbarLogo {
	height: 30px;
}

.navbarLinks {
	display: flex;
	margin-left: auto;
}

.navbarLink {
	margin-left: 25px;
	font-size: 12px;
	padding-top: 5px;
	padding-bottom: 5px;
	border-bottom: 2px solid #fff;
	text-decoration: none;
	color: black !important;
	font-family: 'Futura Book';
	display: flex;
	align-items: flex-end;
}

.navbarLink.selected {
	border-bottom: 2px solid #ffb500;
}

.mobileNavbarLinks .navbarDropdownLink.selected .dropdown-item {
	border-bottom: 2px solid #ffb500;
}

.navbarDropdownLink {
	font-size: 12px;
	color: black !important;
	font-family: 'Futura Book';
	text-decoration: none;
}

.navButton {
	background: #ffb500;
	border: none;
	color: #000;
	font-family: 'Futura Book';
	font-size: 12px;
	padding: 9px 17.5px;
	cursor: pointer;
}

.navbarLinks .dropdown {
	display: flex;
	align-items: flex-end;
}

.navbarLinks .dropdown-menu {
	border: none;

	padding: 10px 0 !important;
}

.dropdown-item.active,
.dropdown-item:active {
	color: #000 !important;
	text-decoration: none;
	background-color: #ffb500 !important;
}

.navdropdown {
	width: fit-content;
	height: fit-content;
	background: white !important;
	border: none !important;
	padding: 0 !important;
	margin: 0;
	font-size: 16px;
	outline: none !important;
	box-shadow: none !important;
}

.navbarDropdownLink .dropdown-item {
	padding: 10px 15px !important;
}

.primaryButton {
	background: #ffb500;
	border: none;
	color: #000;
	font-family: 'Futura Book';
	font-size: 12px;
	padding: 14px 17.5px;
	cursor: pointer;
	width: fit-content;
}

.sub-nav-tagline {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 50px;
	font-size: 16px;
}

.mainSection {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 400px;
}

.productGrid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	margin-top: 40px;
}

.gridProduct {
	max-width: 520px;
	width: 100%;
	margin-bottom: 25px;
	min-height: 150px;
	box-shadow: 6px 6px 12px #00000029;
	display: flex;
	align-items: center;
	padding: 15px;
	color: black !important;
	text-decoration: none !important;
}

.gridProduct img {
	max-width: 180px;
	max-height: 100%;
}

.contentAccordion {
	height: 100%;
	max-height: 130px;
	/* animate exapnded height */
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;
	padding: 16px 25px;
}

.contentAccordion.expanded {
	max-height: 3200px;
	/* animate exapnded height */
	transition: max-height 0.5s ease-in-out;
}

.accordionHeader {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.accordionHeader .titleImage {
	width: 100px;
	height: 100px;
	margin-right: 25px;
}

.accordionHeader .titleImage img {
	width: 100%;
	height: 100%;
	min-width: 100px;
	min-height: 100px;
}

.accordionHeader .title {
	width: 100%;
	max-width: 750px;
	margin-right: 30px;
}

.accordionHeader .expandIcon {
	margin-left: auto;
	width: 30px;
	height: 30px;
}

.iconContainer {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}

.goldBar {
	width: 4px;
	height: 30px;
	background: #ffb500;
	transition: transform 0.5s ease-in-out;
	transform: rotate(90deg);
}

.goldBar.h-bar {
	transform: rotate(0deg);
	/* animate rotation */
	transition: transform 0.5s ease-in-out;
}

.accordionBody {
	width: 100%;
	max-width: 750px;
	margin: 0 auto;
	margin-top: 45px;
}

.bulletPoint {
	display: flex;
}

.darkContentBlock {
	background: transparent linear-gradient(90deg, #425361 0%, #303c42 100%) 0% 0% no-repeat padding-box;
	padding: 30px;
	width: 100%;
	margin-top: 40px;
}

.contentSeparator {
	width: 1px;
	height: 64px;
	background: white;
	margin: 0 45px;
}

.charTableLeft {
	width: 50%;
	padding-right: 15px;
}

.charTableRight {
	width: 50%;
	padding-right: 15px;
}

.floatingBox {
	width: 379px;
	height: 277px;
	/* UI Properties */
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 12px #00000029;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.floatingBox2 {
	width: 100%;
	padding: 75px 92px;
	/* UI Properties */
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 17px #00000029;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.floatingBox3 {
	width: 495px;
	height: 362px;
	/* UI Properties */
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 6px 6px 12px #00000029;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 54.5px 32px;
}

.gradientSection {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 75px 0;
	width: 100%;
	min-height: 440px;
	background: transparent linear-gradient(90deg, #3f5b6f 0%, #313c41 100%) 0% 0% no-repeat padding-box;
}

.gradientSection2 {
	display: flex;
	align-items: center;
	padding: 75px 0;
	width: 100%;
	min-height: 425px;
	background: transparent linear-gradient(90deg, #3f5b6f 0%, #313c41 100%) 0% 0% no-repeat padding-box;
}

.gradientSection3 {
	display: flex;
	align-items: center;
	padding: 100px 0;
	width: 100%;
	min-height: 528px;
	background: transparent linear-gradient(90deg, #3f5b6f 0%, #313c41 100%) 0% 0% no-repeat padding-box;
}

.instrumentPicture {
	width: 142px;
	height: 142px;
}

.numberCircle {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background: #ffb500;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	color: black;
	font-family: 'Futura Bold';
}

.instruments {
	justify-content: space-between;
}

.gradientImages {
	max-height: 225px;
	width: 100%;
	height: 100%;
}

.threeCircles {
	justify-content: space-between;
	margin-top: 58px;
}

.pointer {
	cursor: pointer;

}